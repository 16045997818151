import React, {Component} from 'react';
import {Redirect} from 'react-router-dom';
import {Button} from "semantic-ui-react";

export default class Admin extends Component {
    constructor(props) {
        super(props);

        this.state = {
            toDoll: false,
            toItem: false,
            toVideo: false,
            toLogout: false
        }
    }

    gotoDoll = () => {
        this.setState(() => ({
            toDoll: true
        }));
    };

    gotoItem = () => {
        this.setState(() => ({
            toItem: true
        }));
    };

    gotoVideo = () => {
        this.setState(() => ({
            toVideo: true
        }));
    };

    gotoHome = () => {
        this.setState(() => ({
            toHome: true
        }));
    };

    render() {
        if (this.state.toDoll === true) {
            return <Redirect to='/admin/doll' />
        } else if (this.state.toItem === true) {
            return <Redirect to='/admin/item' />
        } else if (this.state.toVideo === true) {
            return <Redirect to='/admin/video' />
        } else if (this.state.toHome === true) {
            return <Redirect to='/' />
        }
        return (
            <div className="navigation">
                <h2>Admin</h2>
                <div className={"admin_button"}>
                    <Button primary={true} onClick={this.gotoDoll} content={"Create Doll"}/>
                </div>
                <div className={"admin_button"}>
                    <Button secondary={true} onClick={this.gotoItem} content={"Create Item"} />
                </div>
                <div className={"admin_button"}>
                    <Button secondary={true} onClick={this.gotoVideo} content={"Update Video"} />
                </div>
                <div className={"admin_button extra_margin_top"}>
                    <Button color={"green"} onClick={this.gotoHome} content={"Homepage"} />
                </div>
            </div>
        )
    }
}
