export const ResizeImage = (settings) => {
    const file = settings.file;
    const maxWidth = settings.maxWidth;
    const maxHeight = settings.maxHeight;
    const reader = new FileReader();
    const image = new Image();
    const canvas = document.createElement('canvas');
    const dataURItoBlob = function (dataURI) {
        const bytes = dataURI.split(',')[0].indexOf('base64') >= 0 ?
            atob(dataURI.split(',')[1]) :
            decodeURI(dataURI.split(',')[1]);
        const mime = dataURI.split(',')[0].split(':')[1].split(';')[0];
        const max = bytes.length;
        let ia = new Uint8Array(max);
        for (let i = 0; i < max; i++)
            ia[i] = bytes.charCodeAt(i);
        return new Blob([ia], { type: mime });
    };

    const resize = function () {
        let width = image.width;
        let height = image.height;
        if (width > height) {
            console.log('Width ' + width + ' greater than height ' + height);
            if (width > maxWidth) {
                height *= maxWidth / width;
                width = maxWidth;
            }
            console.log('New width ' + width + ' new height ' + height);
        } else {
            console.log('Width ' + width + ' not greater than height ' + height);
            if (height > maxHeight) {
                width *= maxHeight / height;
                height = maxHeight;
            }
            console.log('New width ' + width + ' new height ' + height);
        }
        canvas.width = width;
        canvas.height = height;

        let ctx = canvas.getContext('2d');
        ctx.drawImage(image, 0, 0, width, height);

        const dataUrl = canvas.toDataURL(file.type);
        return {
            blob: dataURItoBlob(dataUrl),
            dataUrl: dataUrl
        };
    };

    return new Promise(function (ok, no) {
        console.log('In Promise');
        if (!file.type.match(/image.*/)) {
            no(new Error("Not an image"));
            return;
        }
        reader.onload = function (readerEvent) {
            image.onload = function () { return ok(resize()); };
            image.src = readerEvent.target.result;
        };
        reader.readAsDataURL(file);
    });
};
