import React, {Component, createRef} from 'react';
import ReactGA from 'react-ga';
import moment from 'moment';
import Admin from './components/Admin';
import Navigation from './components/Navigation';
import {PrivateRoute, REACT_APP_GA_TRACKING_ID, REACT_APP_GTM_ID} from './constants';
import {AuthService} from "./services";
import {Icon, Sticky} from 'semantic-ui-react'
import TagManager from 'react-gtm-module';
import book_icon from './assets/images/book.svg';
import info_icon from './assets/images/info.png';

import './App.css';

class App extends Component {
    contextRef = createRef();

    constructor(props) {
        super(props);
        const trackingId = process.env.REACT_APP_GA_TRACKING_ID ? process.env.REACT_APP_GA_TRACKING_ID : REACT_APP_GA_TRACKING_ID;
        ReactGA.initialize(trackingId);
        ReactGA.pageview('/');
        const gtmId = process.env.REACT_APP_GTM_ID ? process.env.REACT_APP_GTM_ID : REACT_APP_GTM_ID;
        const tagManagerArgs = {
            gtmId: gtmId
        };
        TagManager.initialize(tagManagerArgs)

        this.checkAndUpdateToken();
    }

    checkAndUpdateToken() {
        if (localStorage.user
            && ((localStorage.token_expiry && moment().isAfter(moment(localStorage.token_expiry)))
                || !localStorage.token_expiry)) {
            this.refreshToken();
        }
    }

    refreshToken() {
        AuthService.refreshToken(JSON.parse(localStorage.user).refresh_token);
    }

    render() {
        return (
            <div className="app" ref={this.contextRef}>
                <Sticky context={this.contextRef}>
                    <a href="/">
                        <header className="app-header">
                            <div className="header-title">
                                <img className="header-book-icon" src={book_icon} alt="Icon" />
                                Biblical Babies
                            </div>
                        </header>
                    </a>
                </Sticky>

                <div className="info">
                    <div className="info-box" >
                        <img className="info-icon" src={info_icon} alt="Info" />
                        Payment plan offered on all dolls. £50 initial payment and 3 monthly paypal or cheque payments.
                    </div>
                </div>

                <Navigation />

                <PrivateRoute exact path="/admin" component={Admin} />

                <footer className="app-footer">
                    <div className="footer-row">
                        <div className="footer-column">
                            <h4>Support</h4>
                            <a href="/privacy">Privacy Policy</a>
                        </div>
                        <div className="footer-column">
                            <h4>Contact</h4>
                            <address>
                                105 Annareagh Road, <br/>
                                Richhill, <br/>
                                Co. Armagh,<br/>
                                BT61 9JY
                            </address>
                            <Icon name="mail"/><a href="mailto:syls777@aol.com?subject=Reborn Dolls">syls777@aol.com</a>
                        </div>
                    </div>
                    <div className="footer-row">
                        <div className="copyright">
                            &copy;Biblical Babies 2020
                            <a href="//www.dmca.com/Protection/Status.aspx?ID=27f3b1c7-2be5-412f-843b-47504a96a055"
                               title="DMCA.com Protection Status" className="dmca-badge"> <img
                                src="https://images.dmca.com/Badges/dmca_protected_sml_120l.png?ID=27f3b1c7-2be5-412f-843b-47504a96a055"
                                alt="DMCA.com Protection Status"/></a>
                            <script src="https://images.dmca.com/Badges/DMCABadgeHelper.min.js"></script>
                        </div>
                    </div>
                </footer>
            </div>
        );
    }
}

export default App;
