import React, {Component} from 'react';
import {Icon} from "semantic-ui-react";

export default class Contact extends Component {
    render() {
        return (
            <div className="navigation">
                <h2>Contact Details</h2>
                <div>
                    <p><Icon name="user" />Sylvia Cowan</p>
                    <p><Icon name="home"/>
                        105 Annareagh Road, <br/>
                        Richhill, <br/>
                        Co. Armagh,<br/>
                        BT61 9JY
                    </p>
                    <p><Icon name="mail"/><a href="mailto:syls777@aol.com?subject=Reborn Dolls">syls777@aol.com</a></p>
                    <p><Icon name="phone"/>02838988068</p>
                    <p><Icon name="phone"/>07756187121</p>
                </div>
            </div>
        );
    }
}
