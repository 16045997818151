import React, {Component} from 'react';
import Slider from "react-slick";
import item_placeholder from '../assets/images/clothes.png';

import ItemCard from './ItemCard';

const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 5000,
    cssEase: "linear",
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: true,
                dots: true
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};

export default class Items extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoaded: false,
            items: []
        };
    }

    componentDidMount() {
        const self = this;
        fetch(`${process.env.REACT_APP_SERVICE_URI}/reborn/v2/items`)
            .then(
                function(response){
                    return response.json();
                }
            ).then(
            function(jsonData){
                self.setState({
                    isLoaded: true,
                    items: jsonData
                });
            });
    }

    getItems() {
        let items = [];
        let filtered = this.state.items.slice(0, 10);

        filtered.forEach((item) => {
            let imageUrl = item_placeholder;
            let buyingLink = '';
            if (item.picture) {
                imageUrl = `${process.env.REACT_APP_SERVICE_URI}/images/item/${item.id}/${item.picture}`
            }
            if (item.link) {
                buyingLink = item.link;
            }
            items.push(
                <ItemCard id={item.id} description={item.description} price={item.price}
                          imageUrl={imageUrl} sold={item.sold} link={buyingLink}/>
            );
        });
        return items;
    }

    getSettings(items) {
        let newSettings = JSON.parse(JSON.stringify(settings));
        if (items.length > 0 && items.length <= 2) {
            newSettings.slidesToShow = items.length;
            newSettings.responsive.forEach(responsive => {
                if (responsive.settings.slidesToShow > items.length) {
                    responsive.settings.slidesToShow = items.length;
                }
            });
        }
        return newSettings;
    }

    render() {
        const items = this.getItems();
        const itemsSettings = this.getSettings(items);
        return (
            <div>
                <h2>Items</h2>
                <Slider {...itemsSettings}>
                    {items}
                </Slider>
            </div>
        );
    }
}
