import React, {Component} from 'react';

export default class Privacy extends Component {
    render() {
        return (
            <div className="navigation">
                <h2>Privacy Policy</h2>
                <div class="justify">
                    <p>
                        Biblical Babies operates https://biblicalbabies.co.uk.
                        This page informs you about our policy of collecting, using and disclosing personal information
                        from our users. By using this website, you agree to the collection and use of information in
                        accordance with this policy.
                    </p>
                    <p>
                        However, we understand the importance of the personal information you provide for accessing or
                        purchasing products from any website. In this policy, we explain how we will process your
                        personal information. Information we use We use your personal information to provide and improve
                        this website and to send you products or services purchased through our website.
                    </p>
                    <p>
                        A cookie is a small amount of data that is created on a computer when a person visits a website
                        on a computer. It usually includes an anonymous unique identifier. Cookies can be used to
                        identify computers. However, it is not used to collect any personal information. In other words,
                        it does not have the functionality to identify a single user of a website.
                    </p>
                    <p>
                        Biblical Babies uses cookies to collect statistics about the number of times users visit our
                        site and the user's preferences for the site and the online services offered. You can choose to
                        accept or decline cookies. If you reject these cookies, you will not be able to use certain
                        features of our website, such as saving your preferences and accessing certain online services.
                        Provide personal information unless permitted or required by law.
                        Biblical Babies does not collect or store any personal information
                    </p>
                </div>
            </div>
        );
    }
}
