import React, {Component} from 'react';
import {FilePond, registerPlugin} from "react-filepond";
import axios from "axios";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview)

export default class UploadVideo extends Component {

  render() {
    return (
      <div className="App">
        <center>
          <h1>Upload a Video</h1>

          <FilePond
            allowMultiple={false}
            chunkSize={102400}
            chunkUploads={true}
            labelIdle='Drag & Drop your picture or <span class="filepond--label-action">Browse</span>'
            styleLoadIndicatorPosition='center'
            styleProgressIndicatorPosition='center'
            server={
              {
                process: (fieldName, file, metadata, load, error, progress, abort) => {

                  const formData = new FormData()
                  formData.append('file', file, file.name)

                  // aborting the request
                  const CancelToken = axios.CancelToken
                  const source = CancelToken.source()

                  axios({
                    method: 'POST',
                    url: `${process.env.REACT_APP_SERVICE_URI}/reborn/v2/video/upload`,
                    data: formData,
                    cancelToken: source.token,
                    onUploadProgress: (e) => {
                      // updating progress indicator
                      progress(e.lengthComputable, e.loaded, e.total)
                    }
                  }).then(response => {
                    // passing the file id to FilePond
                    load(response.file)
                  }).catch((thrown) => {
                    if (axios.isCancel(thrown)) {
                      console.log('Request canceled', thrown.message)
                    } else {
                      // handle error
                    }
                  })
                  // Setup abort interface
                  return {
                    abort: () => {
                      source.cancel('Operation canceled by the user.')
                      abort()
                    }
                  }
                }
              }
            }
          />
        </center>
      </div>
    );
  }
}
