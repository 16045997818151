import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter as Router} from "react-router-dom";
import App from "./App";
import axios from "axios";
import {defineCustomElements} from '@ionic/pwa-elements/loader';
import {AuthHeader} from "./helpers";

axios.interceptors.request.use(
  request => {
    if (request.url.includes('/video/upload')) {
      request.headers = AuthHeader()
    }
    return request
  }
)

ReactDOM.render(
    <Router>
        <App />
    </Router>,
    document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();

defineCustomElements(window);
