import React, {Component} from 'react';
import {Card, Confirm, Icon, Menu, MenuItem} from 'semantic-ui-react';
import ReactModal from 'react-modal';
import baby_placeholder from '../assets/images/baby-placeholder.png';
import Images from "./Images";
import {AuthHeader} from "../helpers";
import ebay_icon from '../assets/images/ebay-png-icon-16.jpg';
import {Link} from "react-router-dom";
import ReactGA from "react-ga";

function getSwitch(sold, toggleFn) {
    return (
        <div className="sold-switch doll-admin-menu-item-elem">
            <label className="form-label">Sold</label>
            <label className="switch">
                <input type="checkbox" className="medium-input" onChange={toggleFn} checked={sold}/>
                <span className=" slider round"></span>
            </label>
        </div>
    )
}

function SoldToggle(props) {
    return localStorage.getItem('auth')
        ? getSwitch(props.sold, props.toggleFn)
        : null;
}

export default class DollCard extends Component {
    constructor (props) {
        super(props);

        this.state = {
            showModal: false,
            sold: props.sold,
            openConfirm: false,
            mouseOver: false,
            edit: false
        };

        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.handleSoldToggle = this.handleSoldToggle.bind(this);
        this.deleteDoll = this.deleteDoll.bind(this);
    }

    handleOpenModal () {
        this.setState({ showModal: true });
    }

    handleCloseModal () {
        this.setState({ showModal: false });
    }

    handleSoldToggle () {
        fetch(`${process.env.REACT_APP_SERVICE_URI}/reborn/v2/dolls/${this.props.id}/sold`, {
            method: 'POST',
            body: JSON.stringify(!this.state.sold),
            headers: AuthHeader({'Content-Type': 'application/json'})
        }).then(resp => {
            this.setState({sold: !this.state.sold});
            window.location.reload();
        }).catch(error => {
            console.error(error);
        });
    }

    deleteDoll () {
        fetch(`${process.env.REACT_APP_SERVICE_URI}/reborn/v2/dolls/${this.props.id}`, {
            method: 'DELETE',
            headers: AuthHeader({'Content-Type': 'application/json'})
        }).then(() => {
            this.closeConfirm();
            window.location.reload();
        }).catch(error => {
            console.error(error);
        });
    }

    openLink(props) {
        ReactGA.event({
            category: "Ebay link",
            action: "User viewed the doll " + props.id + " on ebay " + props.link
        });
        const win = window.open(props.link, '_blank');
        if (win != null) {
            win.focus();
        }
    }

    openConfirm = () => this.setState({ openConfirm: true });
    closeConfirm = () => this.setState({ openConfirm: false });
    setMouseOver = () => this.setState({mouseOver: true});
    setMouseOut = () => this.setState({mouseOver: false});
    toggleMouseOver = () => this.setState({mouseOver: !this.state.mouseOver});
    editDoll = () => {
        window.location.pathname = '/admin/edit/doll/' + this.props.id;
    };

    render() {
        let imageUrl = (this.props.imageUrl) ? this.props.imageUrl : baby_placeholder;
        return (
            <Card id={"doll_" + this.props.id} className={this.props.class} onMouseEnter={this.setMouseOver}
                  onMouseLeave={this.setMouseOut} onClick={this.toggleMouseOver}>
                <Menu className="doll-admin-menu" hidden={!this.state.mouseOver || !localStorage.getItem('auth')} >
                    <MenuItem className="doll-admin-menu-item" name="Delete">
                        <div className="doll-admin-menu-item-elem">
                            <Icon name="trash alternate outline" onClick={this.openConfirm}/>
                            <div>Delete</div>
                        </div>
                    </MenuItem>
                    <MenuItem className="doll-admin-menu-item" name="Sold">
                        <div className="doll-admin-menu-item-elem">
                            <Icon name="pencil alternate" onClick={this.editDoll}/>
                            <div>Edit</div>
                        </div>
                    </MenuItem>
                    <MenuItem className="doll-admin-menu-item" name="Sold">
                        <SoldToggle toggleFn={this.handleSoldToggle} sold={this.state.sold} />
                    </MenuItem>
                </Menu>
                <div className="doll-image">
                    <div className="frame">
                        <img className="small" src={imageUrl} alt="Unavailable" />
                    </div>
                </div>
                <div className="moreImages" hidden={this.props.imageUrl === baby_placeholder} >
                    <div onClick={this.handleOpenModal}>More Images</div>
                </div>
                <Confirm open={this.state.openConfirm} onCancel={this.closeConfirm}
                         onConfirm={this.deleteDoll} size='mini'
                         content='You are about to delete the doll, are you sure?'/>
                <ReactModal isOpen={this.state.showModal} contentLabel="More doll images">
                    <button className="close-button" onClick={this.handleCloseModal}><Icon name="close"/></button>
                    <Images id={this.props.id} type={"dolls"}/>
                </ReactModal>
                <Card.Content>
                    <Card.Header>{this.props.name}</Card.Header>
                    <Card.Description>{this.props.description}.</Card.Description>
                </Card.Content>
                <Card.Content extra>
                    <Link hidden={!this.props.link || this.props.sold} onClick={() => this.openLink(this.props)} >
                        Buy on &nbsp;
                        <img alt="Ebay Icon" src={ebay_icon} height={40} width={40} />
                    </Link>
                    <Icon name='pound'/>{this.props.sold ? 'Sold' : this.props.price}
                </Card.Content>
            </Card>
        );
    }
}
