import React from 'react';
import Form from "react-bootstrap/Form";
import {AuthHeader} from "../helpers";
import {ResizeImage} from "../constants";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import {Icon} from "semantic-ui-react";

export const EditService = {
    changeOrientation,
    deleteImage,
    getExtraImagePreview,
    getExtraImagesPreview,
    getExtraImageLabel,
    handleImageAdd,
    handleImageChange,
    handleVideoChange,
    rotateRight,
    rotateRightMultiple,
    updateVideo,
    uploadDollImage,
    uploadExtraImages
};

function changeOrientation(srcBase64, srcOrientation, callback) {
    let img = new Image();

    img.onload = function() {
        let width = img.width,
            height = img.height,
            canvas = document.createElement('canvas'),
            ctx = canvas.getContext("2d");

        // set proper canvas dimensions before transform & export
        if (4 < srcOrientation && srcOrientation < 9) {
            // noinspection JSSuspiciousNameCombination
            canvas.width = height;
            canvas.height = width;
        } else {
            canvas.width = width;
            canvas.height = height;
        }

        // transform context before drawing image
        switch (srcOrientation) {
            case 2: ctx.transform(-1, 0, 0, 1, width/2.5, 0); break;
            case 3: ctx.transform(-1, 0, 0, -1, width/2.5, height ); break;
            case 4: ctx.transform(1, 0, 0, -1, 0, height ); break;
            case 5: ctx.transform(0, 1, 1, 0, 0, 0); break;
            case 6: ctx.transform(0, 1, -1, 0, height , 0); break;
            case 7: ctx.transform(0, -1, -1, 0, height , width/2.5); break;
            case 8: ctx.transform(0, -1, 1, 0, 0, height*(width/height)); break;
            default: break;
        }

        // draw image
        ctx.drawImage(img, 0, 0);

        // export base64
        callback(canvas.toDataURL("image/jpeg"));
    };

    img.src = srcBase64;
}

function deleteImage(self, state, filename) {
    const dollId = self.state.id;
    const dollUrl = `${process.env.REACT_APP_SERVICE_URI}/reborn/v2/dolls/${dollId}`;
    fetch(dollUrl + '/image/' + filename, {
        method: 'DELETE',
        headers: AuthHeader()
    }).catch(error => console.log("Failed to delete image", error));
    setTimeout(function(){
        self.setState({
            additionalImages: self.state.additionalImages.filter(item => item.file !== filename),
            reloadImages: true
        });
    }, 500);
}

function getExtraImagePreview(self, state, extraImageFiles, isNewImage) {
    const label = isNewImage ? 'Added Images' : 'Extra Images';
    return extraImageFiles.map((obj, index) => {
        const fileName = extraImageFiles[index].file;
        if (index === 0) {
            return (
                <Form.Group as={Row} controlId={"preview_" + fileName}>
                    <Form.Label column sm={2} >
                        {label}
                    </Form.Label>
                    <Col sm={7}>
                        <div>
                            <img alt="Preview" src={extraImageFiles[index].imagePreviewUrl}
                                 onClickCapture={() => EditService.rotateRightMultiple(self, index, isNewImage)}/>
                        </div>
                    </Col>
                    <Col sm={2}>
                        <Button variant={"danger"} onClick={() => EditService.deleteImage(self, state, fileName)}>
                            <Icon name="trash alternate outline" value="Delete" />Delete
                        </Button>
                    </Col>
                </Form.Group>
            );
        } else {
            return (
                <Form.Group as={Row} controlId={"preview_" + obj.file.name}>
                    <Form.Label column sm={2} />
                    <Col sm={7}>
                        <div>
                            <img alt="Preview" src={extraImageFiles[index].imagePreviewUrl}
                                 onClickCapture={() => EditService.rotateRightMultiple(self, index, isNewImage)}/>
                        </div>
                    </Col>
                    <Col sm={2}>
                        <Button variant={"danger"} onClick={() => EditService.deleteImage(self, state, fileName)}>
                            <Icon name="trash alternate outline" value="Delete" />Delete
                        </Button>
                    </Col>
                </Form.Group>
            );
        }
    });
}

function getExtraImagesPreview(self, state) {
    let {extraImageFiles} = state;
    let extraImagesPreview = [];
    if (self.state.additionalImages) {
        extraImagesPreview.push(EditService.getExtraImagePreview(self, self.state, self.state.additionalImages, false));
    }
    if (extraImageFiles && extraImageFiles.length > 0) {
        extraImagesPreview.push(EditService.getExtraImagePreview(self, self.state, extraImageFiles, true));
    }

    return extraImagesPreview;
}

function getExtraImageLabel(self, state) {
    let {extraImageFiles} = state;
    let addExtraImagesLabel = null;
    if ((extraImageFiles && extraImageFiles.length > 0) || (self.state.additionalImages)) {
        addExtraImagesLabel = <Form.Label column sm={2} />
    } else {
        addExtraImagesLabel =
            <Form.Label column sm={2}>
                Extra Images
            </Form.Label>
    }

    return addExtraImagesLabel;
}

function handleImageAdd(self, e) {
    e.preventDefault();
    ResizeImage({
        file: e.target.files[0],
        maxWidth: 388,
        maxHeight: 450
    }).then(function (resizedImage) {
        self.state.resizedFiles[self.state.resizedFiles.length] = {
            file: resizedImage.blob,
            imagePreviewUrl: resizedImage.dataUrl
        };
    }).catch(function (err) {
        console.error(err);
    });
    setTimeout(function () {
        self.setState({
            extraImageFiles: self.state.resizedFiles
        })
    }, 300);

}

function handleImageChange(self, e) {
    e.preventDefault();

    ResizeImage({
        file: e.target.files[0],
        maxWidth: 350,
        maxHeight: 450
    }).then(function (resizedImage) {
        self.setState({
            file: resizedImage.blob,
            imagePreviewUrl: resizedImage.dataUrl,
            primaryImageChange: true
        });
    }).catch(function (err) {
        console.error(err);
    });
}

function rotateRight(self) {
    let imgSrc = self.state.imagePreviewUrl;
    changeOrientation(imgSrc, 6, function (rotatedBase64Image) {
        self.setState({
            imagePreviewUrl: rotatedBase64Image
        });
    });
}

function rotateRightMultiple(self, i, isNewImage) {
    let imgSrcs = self.state.extraImageFiles;
    if (isNewImage) {
        changeOrientation(imgSrcs[i].imagePreviewUrl, 6, function (rotatedBase64Image) {
            imgSrcs[i].imagePreviewUrl = rotatedBase64Image;
        });
        setTimeout(function () {
            self.setState({
                extraImageFiles: imgSrcs
            })
        }, 300);
    }
}

function uploadDollImage(self, id) {
    let formData = new FormData();
    formData.append('file', self.state.file);
    formData.append('data', self.state.imagePreviewUrl.split('base64,')[1]);
    formData.append('contentType', self.state.file.type)
    formData.append('size', self.state.file.size)
    console.log(self.state);
    return fetch(`${process.env.REACT_APP_SERVICE_URI}/reborn/v2/dolls/${id}/image/primary`, {
        method: 'POST',
        headers: AuthHeader(),
        body: formData
    }).catch(error => console.log("Failed to upload image", error));
}

function updateVideo(self, file) {
    let formData = new FormData();
    formData.append('file', file);
    return fetch(`${process.env.REACT_APP_SERVICE_URI}/reborn/v2/video/upload`, {
        method: 'POST',
        headers: AuthHeader(),
        body: formData
    }).catch(error => console.log("Failed to upload image", error));
}

function handleVideoChange(self, e) {
    e.preventDefault();

    self.setState({
        file: e.target.files[0]
    });
}

function uploadExtraImages(self, id) {
    let promises = [];
    for (let i = 0; i < self.state.extraImageFiles.length; i++) {
        let formData = new FormData();
        formData.append('file', self.state.extraImageFiles[i].file);
        formData.append('data', self.state.extraImageFiles[i].imagePreviewUrl.split('base64,')[1]);
        promises[i] = fetch(`${process.env.REACT_APP_SERVICE_URI}/reborn/v2/dolls/${id}/image`, {
            method: 'POST',
            headers: AuthHeader(),
            body: formData
        }).catch(error => console.log("Failed to upload image", error));
    }
    return promises;
}
