import {Subject} from 'rxjs';
import {AuthService} from "../services";

const subject = new Subject();
const initialState = {
    auth: undefined,
};

let state = initialState;

const authStore = {
    init: async () => {
        const auth = await AuthService.getAuth();
        state = {...state, auth: auth}
        subject.next(state)
    },
    subscribe: setState => subject.subscribe(setState),
    setAuth: (auth) => {
        state = {
            ...state,
            auth: auth,
        };
        subject.next(state);
    },
    clearAuth: () => {
        state = {...state, auth: undefined};
        subject.next(state);
    },
    initialState: async () => {
        const auth = await AuthService.getAuth();
        return auth;
    }
};

export default authStore;
