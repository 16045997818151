import React, {Component} from 'react';
import {Card} from 'semantic-ui-react';
import moment from 'moment';
import DollCard from "./DollCard";
import baby_placeholder from "../assets/images/baby-placeholder.png";
import ReactGA from "react-ga";

export default class NewDolls extends Component {
    constructor(props) {
        super(props);

        ReactGA.pageview('/new');
        this.state = {
            isLoaded: false,
            dolls: []
        };
    }

    componentDidMount() {
        const self = this;
        fetch(`${process.env.REACT_APP_SERVICE_URI}/reborn/v2/dolls`)
            .then(
                function(response){
                    return response.json();
                }
            ).then(
            function(jsonData){
                self.setState({
                    isLoaded: true,
                    dolls: jsonData
                });
            });
    }

    filterNewDolls(doll) {
        return moment(doll.created) >= moment().subtract(7, 'days')
            && !doll.sold;
    }

    getDolls(filterFn) {
        let dolls = [];
        let filtered = this.state.dolls.filter(filterFn);

        filtered.forEach((doll) => {
            let imageUrl = baby_placeholder;
            let buyingLink = '';
            if (doll.picture) {
                imageUrl = `${process.env.REACT_APP_SERVICE_URI}/reborn/v2/dolls/${doll.id}/image/${doll.picture}`;
            }
            if (doll.link) {
                buyingLink = doll.link;
            }
            dolls.push(
                <DollCard id={doll.id} name={doll.name} description={doll.description} price={doll.price}
                          imageUrl={imageUrl} sold={doll.sold} link={buyingLink} class="doll-card"/>
            );
        });
        return dolls;
    }

    render() {
        const dolls = this.getDolls(this.filterNewDolls);
        return (
            <div>
                <h2>New Dolls</h2>
                <Card.Group>
                    {dolls}
                </Card.Group>
            </div>
        );
    }
}
