import React, {Component} from 'react';
import {LazyLoadImage} from 'react-lazy-load-image-component';
import {Card} from 'semantic-ui-react';
import ReactGA from "react-ga";

export default class Images extends Component {
    constructor(props) {
        super(props);

        ReactGA.pageview('/images' + props.type);
        this.state = {
            isLoaded: false,
            images: []
        };
    }

    componentDidMount() {
        const self = this;
        fetch(`${process.env.REACT_APP_SERVICE_URI}/reborn/v2/${self.props.type}/${self.props.id}/images`)
            .then(
                function(response){
                    return response.json();
                }
            )
            .then(
                function(jsonData){
                    const images = [];
                    jsonData.forEach(image => {
                        const imageUrl = `${process.env.REACT_APP_SERVICE_URI}/images/${self.props.id}/${image.fileName}`
                        images.push(
                            <Card className="doll-all-images">
                                <LazyLoadImage alt="Doll image" height={image.height} src={imageUrl}
                                               width={image.width} />
                            </Card>
                        );
                    });
                    self.setState({
                        isLoaded: true,
                        images: images
                    });
                }
            );
    }

    render() {
        return (
            <Card.Group>
                {this.state.images}
            </Card.Group>
        );
    }
}
