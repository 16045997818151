export function AuthHeader(additional) {
    // return authorization header with basic auth credentials
    let user = JSON.parse(localStorage.getItem('auth'));

    if (user && user.access_token) {
        const auth = { 'Authorization': 'Bearer ' + user.access_token };
        return {...auth, ...additional}
    } else {
        return {...additional};
    }
}
