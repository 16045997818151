import S3 from "aws-sdk/clients/s3";
import {Credentials} from "aws-sdk";
import {REACT_APP_AWS_ACCESS_KEY, REACT_APP_AWS_BUCKET, REACT_APP_AWS_SECRET_KEY} from "./constants";

export const SignS3 = async (req) => {
    const awsAccessKey = process.env.REACT_APP_AWS_ACCESS_KEY ? process.env.REACT_APP_AWS_ACCESS_KEY : REACT_APP_AWS_ACCESS_KEY;
    const awsSecretKey = process.env.REACT_APP_AWS_SECRET_KEY ? process.env.REACT_APP_AWS_SECRET_KEY : REACT_APP_AWS_SECRET_KEY;
    const access = new Credentials({
        accessKeyId: awsAccessKey,
        secretAccessKey: awsSecretKey,
    });

    const s3 = new S3({
        credentials: access,
        region: 'eu-west-1',
        signatureVersion: "v4"
    });

    const fileName = req.fileName;
    const fileType = req.fileType;

    const S3_BUCKET = process.env.REACT_APP_AWS_BUCKET ? process.env.REACT_APP_AWS_BUCKET : REACT_APP_AWS_BUCKET;

    const s3Params = {
        Bucket: S3_BUCKET,
        Key: "videos/biblicalbabies-nursery.mp4",
        Expires: 500,
        ContentType: fileType,
        ACL: 'public-read'
    };

    return new Promise(function (ok, no) {
        s3.getSignedUrlPromise('putObject', s3Params).then(response => {
            console.log(response);
            const returnData = {
                signedRequest: response,
                url: `https://${S3_BUCKET}.s3.amazonaws.com/videos/${fileName}`
            };
            // Send it all back
            return ok({success: true, data: {returnData}});
        }, error => {
            console.log(error);
            return no({success: false, error: error});
        });
    });
}
