import React, {Component} from 'react';
import {Dropdown, Menu, Segment} from 'semantic-ui-react'

import Adopted from "./Adopted";
import Contact from "./Contact";
import Dolls from "./Dolls";
import ForAdoption from "./ForAdoption";
import Items from "./Items";
import NewDolls from "./NewDolls";
import CreateDoll from "./CreateDoll";
import CreateItem from "./CreateItem";
import {slide as MobileMenu} from "react-burger-menu";

import '../Navigation.style.scss';
import LoginPage from "./LoginPage";
import {AuthService} from "../services";
import EditDoll from "./EditDoll";
import Privacy from "./Privacy";
import UploadVideo from "./UploadVideo";

export default class Navigation extends Component {
    state = {
        activeItem: '',
        adminItem: null,
        segment: null,
        menuOpen: false,
        auth: ''
    };

    componentDidMount() {
        const self = this;
        const segment = self.getSegment(self.getStateByLocation(), self.getDollId());
        if (AuthService.verifyAccessToken()) {
            self.setState({
                segment: segment,
                adminItem:
                    <Dropdown item text='Admin' position='right' className="admin-menu-item">
                        <Dropdown.Menu>
                            <Dropdown.Header></Dropdown.Header>
                            <Dropdown.Item name='createdoll' onClick={self.handleItemClick}>Create
                                Doll</Dropdown.Item>
                            <Dropdown.Item name='createitem' onClick={self.handleItemClick}>Create
                                Item</Dropdown.Item>
                            <Dropdown.Item name='uploadvideo' onClick={self.handleItemClick}>Update
                                Video</Dropdown.Item>
                            <Dropdown.Item name='logout' onClick={self.handleItemClick}>Logout</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
            });
        } else {
            self.setLoginAdminItem();
        }
    }

    setLoginAdminItem = () => {
        const self = this;
        const { activeItem } = self.state;
        const segment = self.getSegment(self.getStateByLocation(), self.getDollId());
        self.setState({
            segment: segment,
            adminItem:
                <Menu.Item name='login'
                           active={activeItem === 'login'}
                           onClick={self.handleItemClick}
                           position='right' />
        });
    };

    getStateByLocation = () => {
        const pathname = window.location.pathname;
        if (pathname.startsWith('/admin/edit/doll')) {
            return 'editdoll';
        } else if (pathname.startsWith('/admin/edit/item')) {
            return 'edititem';
        } else {
            return pathname.split("/")[1];
        }
    };

    getDollId = () => {
        const pathname = window.location.pathname;
        const dollIdExtracted = pathname.match(/\d+/);
        if (dollIdExtracted) {
            return parseInt(dollIdExtracted[0]);
        }
    };

    handleItemClick = (e, { name }) => {
        e.preventDefault();
        let segment = this.getSegment(name);
        if (name === 'logout') {
            this.logout();
        }
        this.setState({
            activeItem: name,
            segment: segment,
            menuOpen: false
        });
    };

    logout = () => {
        AuthService.logout(true)
    };

    getSegment = (name, dollId) => {
        if (name === 'newborn') {
            return <NewDolls/>
        } else if (name === 'foradoption') {
            return <ForAdoption/>
        } else if (name === 'adopted') {
            return <Adopted/>
        } else if (name === 'items') {
            return <Items/>
        } else if (name === 'contact') {
            return <Contact/>
        } else if (name === 'createdoll') {
            return <CreateDoll/>
        } else if (name === 'createitem') {
            return <CreateItem/>
        } else if (name === 'uploadvideo') {
            return <UploadVideo/>
        } else if (name === 'editdoll') {
            return <EditDoll dollId={dollId}/>
        } else if (name === 'login') {
            return <LoginPage/>
        } else if (name === 'privacy') {
            return <Privacy />
        } else {
            return <Dolls />
        }
    };

    handleStateChange (state) {
        this.setState({menuOpen: state.isOpen});
    };

    render() {
        const { activeItem } = this.state;
        const { adminItem } = this.state;

        return (
            <div className="navigation">
                <MobileMenu width={150} className="mobile-only"
                            isOpen={this.state.menuOpen}
                            onStateChange={(state) => this.handleStateChange(state)}>
                    <Menu.Item
                        name='dolls'
                        active={activeItem === 'dolls'}
                        onClick={this.handleItemClick}
                    />
                    <Menu.Item
                        name='newborn'
                        active={activeItem === 'newborn'}
                        onClick={this.handleItemClick}
                    >New Born</Menu.Item>
                    <Menu.Item
                        name='foradoption'
                        active={activeItem === 'foradoption'}
                        onClick={this.handleItemClick}
                    >For Adoption</Menu.Item>
                    <Menu.Item
                        name='adopted'
                        active={activeItem === 'adopted'}
                        onClick={this.handleItemClick}
                    />
                    <Menu.Item
                        name='items'
                        active={activeItem === 'items'}
                        onClick={this.handleItemClick}
                    />
                    <Menu.Item
                        name='contact'
                        active={activeItem === 'contact'}
                        onClick={this.handleItemClick}
                    />

                    {adminItem}
                </MobileMenu>
                <Menu tabular className="desktop-only">
                    <Menu.Item
                        name='dolls'
                        active={activeItem === 'dolls'}
                        onClick={this.handleItemClick}
                    />
                    <Menu.Item
                        name='newborn'
                        active={activeItem === 'newborn'}
                        onClick={this.handleItemClick}
                    >New Born</Menu.Item>
                    <Menu.Item
                        name='foradoption'
                        active={activeItem === 'foradoption'}
                        onClick={this.handleItemClick}
                    >For Adoption</Menu.Item>
                    <Menu.Item
                        name='adopted'
                        active={activeItem === 'adopted'}
                        onClick={this.handleItemClick}
                    />
                    <Menu.Item
                        name='items'
                        active={activeItem === 'items'}
                        onClick={this.handleItemClick}
                    />
                    <Menu.Item
                        name='contact'
                        active={activeItem === 'contact'}
                        onClick={this.handleItemClick}
                    />

                    {adminItem}
                </Menu>

                <Segment>
                    {this.state.segment}
                </Segment>
            </div>
        );
    }
}
