import React, {Component} from 'react';
import {Redirect} from 'react-router-dom';
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import {AuthHeader} from "../helpers";
import {Header, HeaderContent} from "semantic-ui-react";
import {EditService} from "../services";

export default class CreateItem extends Component {
    constructor(props) {
        super(props);



        this.state = {
            file: '',
            imagePreviewUrl: '',
            validated: false,
            itemCreated: false,
            itemFailed: false,
            resizedFiles: [],
            back: false
        };
        this.onDismiss = this.onDismiss.bind(this);
    }

    onDismiss() {
        this.setState({
            itemCreated: false,
            itemFailed: false
        });
    }

    handleBack = () => {
        this.setState(() => ({
            back: true
        }));
    };

    handleSubmit(event) {
        let self = this;
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
        }
        let data = {
            "description": form.formDescription.value,
            "price": form.formPrice.value,
            "link": form.formUrl.value
        };
        self.createItem(data).then(res => {
            return res.json();
        }).then(function(data) {
            let primaryImagePromise = self.uploadItemImage(data.id);
            primaryImagePromise.then(() => {
                self.setState({
                    itemCreated: true
                });
                window.scrollTo(0, 0);
            }, () => {
                self.setState({
                    itemFailed: true
                });
                window.scrollTo(0, 0);
            });
        }).catch(err => {
            console.error(err);
        });
        self.setState({ validated: true });
        setTimeout(function(){
            self.setState({
                itemCreated: false,
                itemFailed: false
            })
        }, 5000);
    }

    createItem(data) {
        return fetch(`${process.env.REACT_APP_SERVICE_URI}/reborn/v2/items`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: AuthHeader({'Content-Type': 'application/json'})
        }).catch(error => console.log("Failed to create item", error));
    }

    uploadItemImage(itemId) {
        let formData = new FormData();
        formData.append('file', this.state.file);
        formData.append('data', this.state.imagePreviewUrl.split('base64,')[1]);
        return fetch(`${process.env.REACT_APP_SERVICE_URI}/reborn/v2/items/${itemId}/image/primary`, {
            method: 'POST',
            headers: AuthHeader(),
            body: formData
        }).catch(error => console.log("Failed to upload image", error));
    }

    render() {
        let self = this;
        if (this.state.back) {
            return <Redirect to='/admin' />
        }
        let {imagePreviewUrl} = self.state;
        let $imagePreview = null;
        if (imagePreviewUrl) {
            $imagePreview = (
                <Form.Group as={Row} controlId="formPrimaryImagePreview">
                    <Form.Label column sm={2} >
                        Preview
                    </Form.Label>
                    <Col sm={5}>
                        <div>
                            <img alt="Preview" src={imagePreviewUrl} onClickCapture={()=>EditService.rotateRight(self)} />
                        </div>
                    </Col>
                </Form.Group>
            );
        }
        return (
            <Form className="create-doll-form" onSubmit={e => self.handleSubmit(e)}>
                <Alert dismissible variant="success" show={this.state.itemCreated} onClose={this.onDismiss}>
                    Item was created successfully {this.state.itemCreated}
                </Alert>
                <Alert dismissible variant="danger" show={this.state.itemFailed} onClose={this.onDismiss}>
                    Item was failed to be created {this.state.itemFailed}
                </Alert>

                <Header>
                    <HeaderContent content={"Add a new item"} />
                </Header>

                <Form.Group as={Row} controlId="formDescription">
                    <Form.Label column sm={2}>
                        Description
                    </Form.Label>
                    <Col sm={10}>
                        <Form.Control as="textarea" rows="5" required />
                        <Form.Control.Feedback type="invalid">
                            Please provide a valid description.
                        </Form.Control.Feedback>
                    </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="formPrice">
                    <Form.Label column sm={2} >
                        Price (&#163;)
                    </Form.Label>
                    <Col sm={3}>
                        <Form.Control type="text" required />
                        <Form.Control.Feedback type="invalid">
                            Please provide a value for price.
                        </Form.Control.Feedback>
                    </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="formUrl">
                    <Form.Label column sm={2}>
                        Link
                    </Form.Label>
                    <Col sm={8}>
                        <Form.Control type="text" placeholder="Link to ebay" />
                        <Form.Control.Feedback type="invalid">
                            Ebay link
                        </Form.Control.Feedback>
                    </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="formPrimaryImage">
                    <Form.Label column sm={2} >
                        Primary Image
                    </Form.Label>
                    <Col sm={5}>
                        <Form.Control type="file" className="fileInput"
                                      onChange={(e)=>EditService.handleImageChange(self, e)} required />
                    </Col>
                </Form.Group>

                {$imagePreview}

                <Form.Group as={Row}>
                    <Col sm={{ span: 10, offset: 2 }}>
                        <Button variant={"secondary"} className={"admin_button"} onClick={this.handleBack}>
                            Back
                        </Button>
                        <Button variant={"primary"} className={"admin_button"} type={"submit"}>
                            Submit
                        </Button>
                    </Col>
                </Form.Group>
            </Form>
        );
    }
}
