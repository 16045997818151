import React, {Component} from 'react';
import {Redirect} from 'react-router-dom';
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import {Header, HeaderContent, Icon} from 'semantic-ui-react';
import {EditService} from "../services";
import {AuthHeader} from "../helpers";

export default class EditDoll extends Component {
    constructor(props) {
        super(props);

        this.state = {
            imagePreviewUrl: '',
            extraImageFiles: '',
            validated: false,
            extraImagesPreviewUrl: [],
            dollUpdated: false,
            dollFailed: false,
            primaryImageChange: false,
            resizedFiles: [],
            back: false,
            isLoaded: false,
            reloadImages: true,
            additionalImages: []
        };
        this.onDismiss = this.onDismiss.bind(this);
    }

    componentDidMount() {
        const self = this;
        const dollId = self.props.dollId;
        if (undefined !== dollId) {
            const dollUrl = `${process.env.REACT_APP_SERVICE_URI}/reborn/v2/dolls/${dollId}`;
            let promises = [];
            self.setState({
                id: dollId
            });
            promises[0] = fetch(dollUrl);
            promises[1] = fetch(dollUrl + '/images');
            Promise.all(promises).then(responses =>
                Promise.all(responses.map(res => res.json()))
            ).then(jsonData => {
                let additionalImages = jsonData[1].filter(image => image.fileName !== jsonData[0].picture).map(imageObj => {
                    return {
                        file: imageObj.fileName,
                        imagePreviewUrl: dollUrl + '/image/' + imageObj.fileName
                    }
                });
                self.setState({
                    isLoaded: true,
                    name: jsonData[0].name,
                    description: jsonData[0].description,
                    picture: jsonData[0].picture,
                    sold: jsonData[0].sold,
                    price: jsonData[0].price,
                    link: jsonData[0].link,
                    additionalImages: additionalImages
                });
            }).catch(error => console.log("Failed to get doll details and/or image", error));
        }
    }

    onDismiss() {
        this.setState({
           dollUpdated: false,
           dollFailed: false
        });
    }

    handleBack = () => {
        this.setState(() => ({
            back: true
        }));
    };

    handleSubmit(event) {
        let self = this;
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
        }
        let data = {
            "name": form.formName.value,
            "description": form.formDescription.value,
            "price": form.formPrice.value,
            "link": form.formUrl.value
        };
        self.updateDoll(data).then(res => {
            return res.json();
        }).then(function(data) {
            if (self.state.primaryImageChange) {
                let primaryImagePromise = EditService.uploadDollImage(self, data.id);
                primaryImagePromise.then(() => {
                    self.setState({
                        dollUpdated: true
                    });
                    window.scrollTo(0, 0);
                }, () => {
                    self.setState({
                        dollFailed: true
                    });
                    window.scrollTo(0, 0);
                });
            }
            if (self.state.extraImageFiles) {
                let promises = EditService.uploadExtraImages(self, data.id);
                Promise.all(promises).then(() =>{
                    self.setState({
                        dollUpdated: true
                    });
                    window.scrollTo(0, 0);
                }, () => {
                    self.setState({
                        dollFailed: true
                    });
                    window.scrollTo(0, 0);
                })
            }
        }).catch(err => {
            console.error(err);
        });
        self.setState({ validated: true });
        setTimeout(function(){
            self.setState({
                dollUpdated: false,
                dollFailed: false
            })
        }, 5000);
    }

    updateDoll(data) {
        const dollId = this.state.id;
        return fetch(`${process.env.REACT_APP_SERVICE_URI}/reborn/v2/dolls/${dollId}`, {
            method: 'PATCH',
            body: JSON.stringify(data),
            headers: AuthHeader({'Content-Type': 'application/json'})
        }).catch(error => console.log("Failed to create doll", error));
    }

    render() {
        let self = this;
        if (this.state.back) {
            return <Redirect to='/admin' />
        }
        let {imagePreviewUrl} = self.state;
        let imageUrl = `${process.env.REACT_APP_SERVICE_URI}/images/${self.state.id}/${self.state.picture}`
        let $imagePreview = null;
        if (imagePreviewUrl) {
            $imagePreview = (
                <Form.Group as={Row} controlId="formPrimaryImagePreview">
                    <Form.Label column sm={2} >
                        Preview
                    </Form.Label>
                    <Col sm={5}>
                        <div>
                            <img alt="Preview" src={imagePreviewUrl} onClickCapture={() => EditService.rotateRight(self)} />
                        </div>
                    </Col>
                </Form.Group>
            );
        } else if (self.state.picture) {
            $imagePreview = (
                <Form.Group as={Row} controlId="formPrimaryImagePreview">
                    <Form.Label column sm={2} >
                        Preview
                    </Form.Label>
                    <Col sm={5}>
                        <div>
                            <img alt="Preview" src={imageUrl} />
                        </div>
                    </Col>
                </Form.Group>
            );
        }

        let extraImagesPreview = EditService.getExtraImagesPreview(self, this.state);
        let extraImagesLabel = EditService.getExtraImageLabel(self, this.state);
        if (this.state.reloadImages) {
            extraImagesPreview = EditService.getExtraImagesPreview(self, this.state);
            extraImagesLabel = EditService.getExtraImageLabel(self, this.state);
        }

        return (
            <Form className="create-doll-form" onSubmit={e => self.handleSubmit(e)}>
                <Alert dismissible variant="success" show={this.state.dollUpdated} onClose={this.onDismiss}>
                    Doll was updated successfully {this.state.dollUpdated}
                </Alert>
                <Alert dismissible variant="danger" show={this.state.dollFailed} onClose={this.onDismiss}>
                    Doll was failed to be created {this.state.dollFailed}
                </Alert>

                <Header>
                    <HeaderContent content={"Update doll"} />
                </Header>

                <Form.Group as={Row} controlId="formName">
                    <Form.Label column sm={2}>
                        Name
                    </Form.Label>
                    <Col sm={10}>
                        <Form.Control type="text" placeholder="Name" defaultValue={self.state.name} required />
                        <Form.Control.Feedback type="invalid">
                            Please provide a valid name.
                        </Form.Control.Feedback>
                    </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="formDescription">
                    <Form.Label column sm={2}>
                        Description
                    </Form.Label>
                    <Col sm={10}>
                        <Form.Control as="textarea" rows="5" defaultValue={self.state.description} required />
                        <Form.Control.Feedback type="invalid">
                            Please provide a valid description.
                        </Form.Control.Feedback>
                    </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="formPrice">
                    <Form.Label column sm={2} >
                        Price (&#163;)
                    </Form.Label>
                    <Col sm={3}>
                        <Form.Control type="text" defaultValue={self.state.price} required />
                        <Form.Control.Feedback type="invalid">
                            Please provide a value for price.
                        </Form.Control.Feedback>
                    </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="formUrl">
                    <Form.Label column sm={2}>
                        Link
                    </Form.Label>
                    <Col sm={8}>
                        <Form.Control type="text" placeholder="Link to ebay" defaultValue={self.state.link} />
                        <Form.Control.Feedback type="invalid">
                            Ebay link
                        </Form.Control.Feedback>
                    </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="formPrimaryImage">
                    <Form.Label column sm={2} >
                        Primary Image
                    </Form.Label>
                    <Col sm={5}>
                        <Form.Control type="file" className="fileInput"
                                      onChange={(e)=>EditService.handleImageChange(self, e)} required={!self.state.picture} />
                    </Col>
                </Form.Group>

                {$imagePreview}

                {extraImagesPreview}

                <Form.Group as={Row} controlId={"preview_extra_images"}>
                    {extraImagesLabel}
                    <Col sm={5}>
                        <label htmlFor="myInput">
                            <Icon name='plus' size='large' color='green' />
                        </label>
                        <input id="myInput" style={{display:'none'}} type={"file"}
                               onChange={(e)=>EditService.handleImageAdd(self, e)} />
                    </Col>
                </Form.Group>

                <Form.Group as={Row}>
                    <Col sm={{ span: 10, offset: 2 }}>
                        <Button variant={"secondary"} className={"admin_button"} onClick={this.handleBack}>
                            Back
                        </Button>
                        <Button variant={"primary"} className={"admin_button"} type={"submit"}>
                            Submit
                        </Button>
                    </Col>
                </Form.Group>

            </Form>
        );
    }
}
