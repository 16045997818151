import React, {Component} from 'react';
import ReactGA from 'react-ga';
import Slider from "react-slick";
import moment from 'moment';
import {BigPlayButton, ControlBar, Player} from 'video-react';
import DollCard from './DollCard';
import baby_placeholder from '../assets/images/baby-placeholder.png';
import '../assets/css/video-react.css';

const settings = {
    autoplay: true,
    autoplaySpeed: 5000,
    centerMode: true,
    cssEase: "linear",
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    speed: 2000,
    responsive: [
        {
            breakpoint: 900,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 500,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};

export default class Dolls extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoaded: false,
            dolls: []
        };
    }

    componentDidMount() {
        ReactGA.set({'metric1': 'video'});
        const self = this;
        fetch(`${process.env.REACT_APP_SERVICE_URI}/reborn/v2/dolls`)
            .then(
                function(response){
                    return response.json();
                }
            ).then(
            function(jsonData){
                self.setState({
                    isLoaded: true,
                    dolls: jsonData
                });
            });
    }

    static filterNewDolls(doll) {
        return moment(doll.created) >= moment().subtract(7, 'days')
            && !doll.sold;
    }

    static filterSoldDolls(doll) {
        return doll.sold;
    }

    static filterUnsoldDolls(doll) {
        return !doll.sold;
    }

    getDolls(filterFn) {
        let dolls = [];
        let filtered = this.state.dolls.filter(filterFn).slice(0, 10);

        filtered.forEach((doll) => {
            let imageUrl = baby_placeholder;
            let buyingLink = '';
            if (doll.picture) {
                imageUrl = `${process.env.REACT_APP_SERVICE_URI}/images/${doll.id}/${doll.picture}`;
            }
            if (doll.link) {
                buyingLink = doll.link;
            }
            dolls.push(
                <DollCard id={doll.id} name={doll.name} description={doll.description} price={doll.price}
                          imageUrl={imageUrl} sold={doll.sold} link={buyingLink}/>
            );
        });
        return dolls;
    }

    getSettings(dolls) {
        let newSettings = JSON.parse(JSON.stringify(settings));
        if (dolls.length > 0 && dolls.length <= 2) {
            newSettings.slidesToShow = dolls.length;
            newSettings.responsive.forEach(responsive => {
                if (responsive.settings.slidesToShow > dolls.length) {
                    responsive.settings.slidesToShow = dolls.length;
                }
            });
        }
        return newSettings;
    }

    render() {
        const newDolls = this.getDolls(Dolls.filterNewDolls);
        const newDollSettings = this.getSettings(newDolls);
        const soldDolls = this.getDolls(Dolls.filterSoldDolls);
        const soldDollSettings = this.getSettings(soldDolls);
        const unsoldDolls = this.getDolls(Dolls.filterUnsoldDolls);
        const unsoldDollSettings = this.getSettings(unsoldDolls);
        return (
            <div>
                <div className="video">
                    <h3>Have a look around the Biblical Babies nursery</h3>
                    <Player playsInline
                            src={'https://biblicalbabies.co.uk/videos/biblicalbabies-nursery.mp4'}
                            fluid={false}
                            width="100%"
                            height={272} >
                        <source src={this.state.source} />
                        <BigPlayButton position="center" />
                        <ControlBar autoHide={false} />
                    </Player>
                </div>
                <h2>New Borns (<a href="/newborn">view new</a>)</h2>
                <Slider {...newDollSettings}>
                    {newDolls}
                </Slider>
                <h2>For Adoption (<a href="/foradoption">view for adoption</a>)</h2>
                <Slider {...unsoldDollSettings}>
                    {unsoldDolls}
                </Slider>
                <h2>Adopted (<a href="/adopted">view adopted</a>)</h2>
                <Slider {...soldDollSettings}>
                    {soldDolls}
                </Slider>
            </div>
        );
    }
}
